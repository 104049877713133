import React from "react"
import ReactMarkdown from "react-markdown"
import { Link, graphql } from "gatsby"

import { Layout } from "../components"
import { dateString } from "../utils/helper"

import styles from "./cinestories.module.css"

const renderStory = (story) => {
  const regex = /images..*(?:png|jpg)/g
  const storyHasImageMatches = story.text.text.match(regex)

  return (
    <Link className={styles.link} to={`/story/${story.url}`}>
      <div className={styles.story} key={story.url}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>{story.title}</h2>
          </div>

          <span className={styles.info}>
            {`${story.author.name} - ${dateString(story.createdAt)}`}
          </span>
        </div>

        <div className={styles.mainContainer}>
          {storyHasImageMatches && storyHasImageMatches.length > 0 && (
            <img
              className={styles.storyImageThumbnail}
              alt="storyImage"
              src={`https://${storyHasImageMatches[0]}?w=150&h=150&fit=thumb`}
            />
          )}

          <div className={styles.mainText}>
            <ReactMarkdown
              escapeHtml={false}
              source={story.text.text.substring(0, 300) + "..."}
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ({ data }) => {
  const cinestories = data.allContentfulCineStory.edges.map((edge) => edge.node)

  return (
    <Layout>
      <div className={styles.gridContainer}>
        {cinestories.map((story) => renderStory(story))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulCineStory {
      edges {
        node {
          id
          title
          url
          text {
            text
          }
          author {
            name
          }
          createdAt
        }
      }
    }
  }
`
